import { css } from 'styled-components';

import { Colour } from './colours';

export class Skeleton {
  public static readonly Rectangle = css`
    display: flex;
    border-radius: 4px;
    background-color: ${Colour.Gray[100]};
  `;

  public static readonly Circle = css`
    border-radius: 50% !important;
    background-color: ${Colour.Gray[100]};
  `;
}
