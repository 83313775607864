import { Fragment } from 'react';
import { RowData, Table } from '@tanstack/react-table';
import classnames from 'classnames';

import { Checkbox, CheckboxSize } from '@xemplo/checkbox';
import { IconButtonSize, IconButtonVariant } from '@xemplo/icon-button';
import { DawnCross16 } from '@xemplo/icons';

import { DefaultItemCounter } from '../item-counter';
import { formatNumber } from '../table.helper';
import { TableProps } from '../table.types';

import * as S from './action-bar.style';

export const ActionBarTestId = {
  container: 'action-bar-container',
  counter: 'action-bar-counter',
  selectedCounter: 'action-bar-selected-counter',
  customActions: 'action-bar-custom-actions',
  divider: 'action-bar-divider',
  selector: 'action-bar-selector',
  closeButton: 'action-bar-close-button',
};

type ActionBarProps<TData> = {
  table: Table<TData>;
};

export const ActionBar = <TData extends RowData>(props: ActionBarProps<TData>) => {
  const { table } = props;
  const { enableRowSelection, isListTable } = table.options as TableProps<TData>;
  const hasSelected = !!table.getSelectedRowModel().rows.length;
  //  Note: table.getIsSomeRowsSelected() is always false in the second page of the table
  if (!enableRowSelection) return null;

  return (
    <S.Container
      className={classnames('action-bar-container', {
        'has-selected': hasSelected,
        'list-table': isListTable,
      })}
      data-testid={ActionBarTestId.container}
    >
      {isListTable ? <ListTableActionBar {...props} /> : <DefaultActionBar {...props} />}
    </S.Container>
  );
};

function ListTableActionBar<TData>({ table }: ActionBarProps<TData>) {
  const { selectedActions } = table.options as TableProps<TData>;
  const toggleSelect = table.getToggleAllRowsSelectedHandler();
  const isAllSelected = table.getIsAllRowsSelected();
  const { rows } = table.getCoreRowModel();
  const hasSelected = table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();

  return (
    <Fragment>
      <S.SelectAll
        data-testid={ActionBarTestId.selector}
        onClick={(e) => {
          e.preventDefault();
          toggleSelect(e);
        }}
      >
        <Checkbox
          value=""
          checked={isAllSelected}
          indeterminate={table.getIsSomeRowsSelected()}
          ariaLabel="Select all checkbox"
          id="list-table-checkbox-select-all"
          size={CheckboxSize.Small}
        />
        {isAllSelected
          ? 'Deselect all'
          : `Select all (${formatNumber({ value: rows.length })})`}
      </S.SelectAll>
      {selectedActions && hasSelected && (
        <S.SelectedActions className="list-table">
          {selectedActions({ table })}
        </S.SelectedActions>
      )}
    </Fragment>
  );
}

function DefaultActionBar<TData>({ table }: ActionBarProps<TData>) {
  const { selectedActions } = table.options as TableProps<TData>;
  const hasSelected = !!table.getSelectedRowModel().rows.length;
  //  Note: table.getIsSomeRowsSelected() is always false in the second page of the table

  return hasSelected ? (
    <Fragment>
      <SelectedRowCounter table={table} />
      {selectedActions && (
        <S.SelectedActions>{selectedActions({ table })}</S.SelectedActions>
      )}
      <S.CloseButton
        ariaLabel="close action bar button"
        id="close-action-bar"
        naked
        size={IconButtonSize.Small}
        variant={IconButtonVariant.Lighter}
        onClick={() => table.resetRowSelection()}
      >
        <DawnCross16 />
      </S.CloseButton>
    </Fragment>
  ) : (
    <DefaultItemCounter table={table} testId={ActionBarTestId.counter} />
  );
}

function SelectedRowCounter<TData>({ table }: ActionBarProps<TData>) {
  const { rows } = table.getSelectedRowModel();
  const selectedCount = formatNumber({ value: rows.length });

  return (
    <S.CounterWrapper>
      <span data-testid={ActionBarTestId.selectedCounter}>
        {selectedCount} {pluralize(rows.length, 'row', 'rows')} selected
      </span>
      {table.getIsAllPageRowsSelected() && (
        <Fragment>
          <S.Divider>|</S.Divider>
          <SelectAllRows table={table} />
        </Fragment>
      )}
    </S.CounterWrapper>
  );
}

function SelectAllRows<TData>({ table }: ActionBarProps<TData>) {
  const { rows } = table.getCoreRowModel();
  const toggleSelect = table.getToggleAllRowsSelectedHandler();

  return (
    <S.Selector onClick={toggleSelect} data-testid={ActionBarTestId.selector}>
      {table.getIsAllRowsSelected()
        ? 'Deselect all'
        : `Select ${formatNumber({ value: rows.length })}`}
    </S.Selector>
  );
}

function pluralize(value: number, singular: string, plural: string) {
  return value > 1 ? plural : singular;
}
