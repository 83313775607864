import styled from 'styled-components';

import { Colour, MinBreakpoint } from '@xemplo/style-constants';

import { ModalBodyOverflow } from '../body';

export const StyledFooter = styled.footer`
  position: sticky;
  bottom: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 12px;

  & > .extra {
    grid-row: 1;
  }

  & > .tertiaryBtn {
    padding: 0;
  }

  padding: 20px 24px 24px;
  margin-top: auto;
  border-top: 1px solid ${Colour.Gray[200]};

  @media ${MinBreakpoint.small} {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    grid-template-columns: repeat(2, 1fr);

    & > .primaryBtn {
      order: 2;
    }
    & > .secondaryBtn {
      order: 1;
    }
    & > .tertiaryBtn {
      order: 3;
      grid-column: 1 / span 2;
      text-align: center;
    }
    & > .extra {
      grid-column: 1 / span 2;
      grid-row: 1;
      order: 0;
    }
    &.hasPrimaryOrSecondary {
      grid-template-columns: 1fr;
    }
  }

  @media ${MinBreakpoint.medium} {
    display: flex;
    align-items: center;
    padding: 24px 40px 32px;
    & > .tertiaryBtn {
      margin-right: auto;
      order: 0;
    }
  }

  &[data-overflow=${ModalBodyOverflow.Extended}] {
    position: static;
    border-top: 0;
  }
`;
