import { flexRender, RowData, Table } from '@tanstack/react-table';
import styled from 'styled-components';

import { BodySmallRegular, Colour } from '@xemplo/style-constants';

import { hasCustomColumnSize } from '../table.helper';
import { CellWrapper, HeaderCell } from '../table.style';
import { TableProps } from '../table.types';

type TotalsProps<TData> = {
  table: Table<TData>;
};

const StyledRow = styled.tr`
  table-layout: fixed;
  background: ${Colour.Gray[100]};

  th div {
    ${BodySmallRegular};
  }
`;

export function Totals<TData extends RowData>({ table }: TotalsProps<TData>) {
  const { enableTotals, enableRowSelection, enableExpanding } =
    table.options as TableProps<TData>;

  // TODO: It should be enabled only filters are applied
  if (!enableTotals) return null;

  return (
    <StyledRow className="totals">
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) =>
          header.id === 'extra-col' ? (
            <th
              key={`${header.id}-totals`}
              data-selectable={!!enableRowSelection}
              data-expandable={
                enableExpanding &&
                table.getCoreRowModel().rows.some((row) => row.subRows?.length > 0)
              }
            ></th>
          ) : (
            <HeaderCell
              key={`${header.id}-totals`}
              $width={
                hasCustomColumnSize(header.column.id, table.options.columns)
                  ? header.getSize()
                  : undefined
              }
            >
              <CellWrapper>
                {flexRender(header.column.columnDef.footer, header.getContext())}
              </CellWrapper>
            </HeaderCell>
          )
        )
      )}
    </StyledRow>
  );
}
