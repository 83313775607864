import styled from 'styled-components';

import {
  BodySmallRegular,
  BodyStandardSemiBold,
  BodyXSmallSemiBold,
  Colour,
} from '@xemplo/style-constants';

export const StyledNoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  > svg {
    width: 240px;
  }
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

export const StyledNoResultsTitle = styled.span`
  ${BodyStandardSemiBold};
  color: ${Colour.Gray[500]};
`;

export const StyledNoResultsMessage = styled.span`
  ${BodySmallRegular};
  color: ${Colour.Gray[400]};
`;

export const NoResultText = styled.div`
  ${BodyXSmallSemiBold};
  height: 96px;
  color: ${Colour.Gray[300]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
