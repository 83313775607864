export const BaseCdnUrl = 'https://assets.xemplo.com';

export const CdnArea = {
  Image: 'common/images',
  Script: 'common/scripts',
} as const;

export type CdnArea = (typeof CdnArea)[keyof typeof CdnArea];

export const ImageContainer = {
  Mobile: 'mobile',
  GP: 'gp-info-cards',
  svg: 'svg',
  png: 'png',
} as const;
export type ImageContainer = (typeof ImageContainer)[keyof typeof ImageContainer];

export const XemploCdn = (area: CdnArea, container: string, file: string) =>
  `${BaseCdnUrl}/${area}/${container}/${file}`;

export const CdnImage = (container: string, file: string) =>
  XemploCdn(CdnArea.Image, container, file);
