import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { BodySmallMedium, Colour } from '@xemplo/style-constants';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: ${Colour.Gray[100]};
  transition: all 0.1s ease-out;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 24px;
  height: 32px;
  ${BodySmallMedium};
  color: ${Colour.Gray[400]};

  &.has-selected:not(.list-table) {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
  }

  + table {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

export const SelectedActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 48px;

  &.list-table {
    margin-right: 0;
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Divider = styled.span`
  color: ${Colour.White[30]};
`;

export const Selector = styled.span`
  color: ${Colour.White[80]};
  cursor: pointer;
  &:hover {
    color: ${Colour.White[100]};
  }
`;

export const SelectAll = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${BodySmallMedium};
  color: ${Colour.Gray[400]};
  cursor: pointer;
  user-select: none;
`;
